body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.banner {
  background-color: #f7f7f7;
  min-height: 400px;
  max-height: 600px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center
}

.bannerContents {
  margin: 0
}

.banner h1{
  font-size: 55px;
}

.banner p {
  font-size: 20px;
}

.banner h1, p {
  color: #323643;
}

.banner div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.aboutMe {
  background-color: #323643;
  padding-top: 2%;
  padding-bottom: 5%;
}

.aboutMe h1 {
  text-align: center;
  margin-top: 20px;
  color: #93deff;
}

.aboutMe p {
  color: #f7f7f7
}
.skills {
  background-color: #f7f7f7;
  padding-top: 2%;
  padding-bottom: 5%;
}

.skills h1 {
  text-align: center;
  margin-top: 20px;
  color: #93deff;
}

ul {
  list-style-type: none;
}
.projects {
  background-color: #323643;
  padding-top: 2%;
  padding-bottom: 5%;
}

.projects h1 {
  text-align: center;
  margin-top: 20px;
  color: #93deff;
}

.projects h3 {
  text-align: center;
  margin-top: 20px;
  color: #f7f7f7;
}

/* .card-img-top {
  height: 100%;
  width: 100%;
  overflow: hidden;
  height: 300px;

} */

.card {
  margin-bottom: 30px !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%50fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%50fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators .active {
  background-color: #353333;
}

.carousel-indicators li {
  background-color: rgba(49, 48, 48, 0.5) !important;
}

.carousel-caption {
  color: #323643;
}

.unavailable {
  color: #d3d3d8;
}

#gitlab,
#sitelink {
  float: right;
}

.links {
  margin-bottom: 40px;
}
.navbar {
  background-color: #323643;
  border-bottom: #606470 3px solid;
}

.nav-link {
  color: #f7f7f7 !important;
}

.navbar-brand {
  color: #93deff !important;
}

.active {
  color: #93deff !important;
}
.contactMe {
  background-color: #f7f7f7;
  padding-top: 2%;
  padding-bottom: 5%;
}

.contactMe h1 {
  text-align: center;
  margin-top: 20px;
  color: #93deff;
}

ul {
  list-style-type: none;
}

.contactMe p {
  text-align: center;
}

.bannerProject {
  background-color: #f7f7f7;
  min-height: 400px;
  max-height: 600px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center
}

.bannerContents {
  margin: 0
}

/* .bannerProject {
  margin: 0

} */

.bannerProject h1{
  font-size: 55px;
}

.bannerProject p {
  font-size: 20px;
}

.bannerProject h1, p {
  color: #323643;
}

.bannerProject div {
  position: absolute;
  transform: translate(-50%, -50%);
}


.light {
  background-color: #f7f7f7;
  padding-top: 2%;
  padding-bottom: 5%;
}

.dark {
  background-color: #323643;
  padding-top: 2%;
  padding-bottom: 5%;
}

.dark p {
  color: #f7f7f7
}

h1 {
  text-align: center;
  margin-top: 20px;
  color: #93deff;
}

.image {
  height: 100%;
  overflow: hidden;
}

.main-img img {
  width: 100%;
}

.imgs img {
  width: 100%;
}

.imgs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  background-color: #323643;

}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-in 1 forwards;
}

#card-gal {
  border: none;
}

.imageActive {
  opacity: 0.4;
}

.center{
  text-align: center;
}

