.aboutMe {
  background-color: #323643;
  padding-top: 2%;
  padding-bottom: 5%;
}

.aboutMe h1 {
  text-align: center;
  margin-top: 20px;
  color: #93deff;
}

.aboutMe p {
  color: #f7f7f7
}