.banner {
  background-color: #f7f7f7;
  min-height: 400px;
  max-height: 600px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center
}

.bannerContents {
  margin: 0
}

.banner h1{
  font-size: 55px;
}

.banner p {
  font-size: 20px;
}

.banner h1, p {
  color: #323643;
}

.banner div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
