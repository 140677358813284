.bannerProject {
  background-color: #f7f7f7;
  min-height: 400px;
  max-height: 600px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center
}

.bannerContents {
  margin: 0
}

/* .bannerProject {
  margin: 0

} */

.bannerProject h1{
  font-size: 55px;
}

.bannerProject p {
  font-size: 20px;
}

.bannerProject h1, p {
  color: #323643;
}

.bannerProject div {
  position: absolute;
  transform: translate(-50%, -50%);
}


.light {
  background-color: #f7f7f7;
  padding-top: 2%;
  padding-bottom: 5%;
}

.dark {
  background-color: #323643;
  padding-top: 2%;
  padding-bottom: 5%;
}

.dark p {
  color: #f7f7f7
}

h1 {
  text-align: center;
  margin-top: 20px;
  color: #93deff;
}

.image {
  height: 100%;
  overflow: hidden;
}

.main-img img {
  width: 100%;
}

.imgs img {
  width: 100%;
}

.imgs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  background-color: #323643;

}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-in 1 forwards;
}

#card-gal {
  border: none;
}

.imageActive {
  opacity: 0.4;
}

.center{
  text-align: center;
}