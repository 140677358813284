.navbar {
  background-color: #323643;
  border-bottom: #606470 3px solid;
}

.nav-link {
  color: #f7f7f7 !important;
}

.navbar-brand {
  color: #93deff !important;
}

.active {
  color: #93deff !important;
}