.projects {
  background-color: #323643;
  padding-top: 2%;
  padding-bottom: 5%;
}

.projects h1 {
  text-align: center;
  margin-top: 20px;
  color: #93deff;
}

.projects h3 {
  text-align: center;
  margin-top: 20px;
  color: #f7f7f7;
}

/* .card-img-top {
  height: 100%;
  width: 100%;
  overflow: hidden;
  height: 300px;

} */

.card {
  margin-bottom: 30px !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%50fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%50fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators .active {
  background-color: #353333;
}

.carousel-indicators li {
  background-color: rgba(49, 48, 48, 0.5) !important;
}

.carousel-caption {
  color: #323643;
}

.unavailable {
  color: #d3d3d8;
}

#gitlab,
#sitelink {
  float: right;
}

.links {
  margin-bottom: 40px;
}