.contactMe {
  background-color: #f7f7f7;
  padding-top: 2%;
  padding-bottom: 5%;
}

.contactMe h1 {
  text-align: center;
  margin-top: 20px;
  color: #93deff;
}

ul {
  list-style-type: none;
}

.contactMe p {
  text-align: center;
}